/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Header from "components/header";
import { Box } from "@material-ui/core";
import { makeStyles, createStyles, styled, useTheme } from "@material-ui/styles";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { createTransactionSuccess } from "redux/reducers/payment";
import { useHistory } from "react-router-dom";
import './Layout.css'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      // marginTop: 110,
      backgroundColor: theme.palette.primary.main,
      height: '100vh',
    },
    scrollMargin: {
      scrollMarginTop: "120px",
    },
    image: {
      width: "100%",
      height: 516,
      objectFit: "cover",

      [theme.breakpoints.down("lg")]: {
        height: 230,
      },
    },
  })
);

const Body = styled(Box)(({theme}) => ({
  padding: '30px',
}))

function LayoutStandard(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  
  useEffect(() => {
    if(window.localStorage.getItem('TRX_CURRENT')) {
      const trxCurrent = JSON.parse(atob(window.localStorage.getItem('TRX_CURRENT')))
      console.log("🚀 ~ file: Standard.js ~ line 43 ~ useEffect ~ trxCurrent", trxCurrent)
      const path = history.location.pathname
      dispatch(
        createTransactionSuccess({
          transactionId: trxCurrent.id,
          qrContent: trxCurrent.qr_content,
          price: trxCurrent.price,
          codeTransaction: trxCurrent.code_transaction,
        })
      );
 
      if(path.indexOf('payment') < 0) {
        history.push('/payment')
      }
      
    }
  }, [])

  return (
    <Box className={classNames(classes.root, "font-primary")}>
      <Header/>
      <Body>
        <div class="content-wrapper">
          {props.children}
        </div>
      </Body>
    </Box>
  );
}

export default LayoutStandard;
