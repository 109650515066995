/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react";
import Button from "components/Button";
import {SecondaryText} from "components/Typography";
import Card from "components/Card";
import {Title} from "components/Typography";
import LayoutStandard from "components/Layout/Standard";
import {
  useTheme,
} from "@material-ui/styles";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import * as paymentService from 'services/payment';
import Countdown from 'react-countdown';
import Dialog from 'components/Dialog'
import useAuth from "hooks/useAuth";
import { formatRupiah } from "utils/helpers";
import CloseIcon from '@material-ui/icons/Close';
import { api } from 'utils/endpoint'
import useBooth from "hooks/useBooth";
// window.dateValue = Date.now() + (5*60000);

const checkNumber = (value) => value >= 10 ? value : `0${value}`;

function Payment() {
  useAuth();
  const theme = useTheme();
  const history = useHistory();
  const booth = useBooth();
  const { data } = useSelector((state) => state.payment);
  console.log("🚀 ~ file: index.js ~ line 31 ~ Payment ~ data", data)
  const [ disabledBtn, setDisabledBtn ] = useState(false);
  const [ showDialogPending, setShowDialogPending ] = useState(false);
  const [ showDialogCancel, setShowDialogCancel ] = useState(false);
  const [ disabledBtnCancel, setDisabledBtnCancel ] = useState(false);
  const [timerValue, setTimerValue] = useState(null)

  const checkPaymentStatus = (forceFailed = false) => {
    setDisabledBtn(true);
    const transactionId = data.transactionId;
    // const transactionId = '8289ec71-75ef-4912-9b72-3078dc4a95fe';
    paymentService.getStatus({transactionId, forceFailed}, (response, isError) => {
      setDisabledBtn(false);
      if(!isError) {
        if(response.data.transactionStatus === 'paid') {
          history.push('/payment/success');
        } else if(['unpaid', 'failed'].indexOf(response.data.transactionStatus) >= 0) {
          if(forceFailed) {
            // sent cancel transaction to server
  
            // redirect
            history.push('/payment/failed');
          }
          setShowDialogPending(true);
        } else {
          history.push('/payment/failed');
        }
      }
    })
  }

  const cancelTransaction = () => {
    setDisabledBtnCancel(true);
    const transactionId = data.transactionId;
    const payload = {
      id: transactionId,
      status: 'cancelled',
    }
    paymentService.update(payload, (response, isError) => {
      setDisabledBtnCancel(false);
      if(!isError) {
        
        window.localStorage.removeItem('TRX_CURRENT')
        window.localStorage.removeItem('TIMER_VALUE')
        history.push('/')
      }

    })
  }
  
  const handleCompleteCountdown = () => {
    checkPaymentStatus(true);
  }
  
  const handleClose = () => {
    setShowDialogPending(false)
  }

  const handleCloseCancel = () => {
    setShowDialogCancel(false)
  }
  
  useEffect(() => {
    if(!window.localStorage.getItem('TRX_CURRENT')) {
      history.push('/')
    } else {
      if(!window.localStorage.getItem('TIMER_VALUE')) {
        window.localStorage.setItem('TIMER_VALUE', Date.now() + (data.expiredInMinutes*60000));
      } else {
        if(Date.now() > window.localStorage.getItem('TIMER_VALUE')) {
          checkPaymentStatus(true)
        }
      }

      setTimerValue(window.localStorage.getItem('TIMER_VALUE'))

      window.localStorage.removeItem('TRX_ACTIVE')
    }
    
  }, [data])

  useEffect(() => {
    booth.updateBooth({
      status: 'progress',
      progress_status: 'payment',
    })
  }, [])
  
  return (
    <LayoutStandard>
      <div className="flex flex-col items-center justify-center text-center">
        <SecondaryText className="my-4">Scan QRIS Berikut</SecondaryText>
        <Card className="flex flex-col justify-center items-center" style={{width: '100%'}}>
          {data.transactionId && (
            <img src={`${api}/transaction/${data.transactionId}/qrcode`} alt="qris" style={{ height: 250, width: 250 }}/>
          )}
          <div style={{fontSize: 32, fontWeight: 'bold', marginTop: 12}}>
            {timerValue && (
            <Countdown 
              // date={Date.now() + (5*60000)} 
              date={Number(timerValue)} 
              intervalDelay={3} 
              precision={3}
              onComplete={handleCompleteCountdown}
              // onStop
              renderer={({hours, minutes,seconds, completed}) => (!completed) ? <span>{checkNumber(hours)}:{checkNumber(minutes)}:{checkNumber(seconds)}</span> : ''}
              >
            </Countdown>
            )}
          </div>
        </Card>
        <div className="text-center mt-8">
          <span className="font-secondary text-uppercase text-white">Yang harus dibayar:</span>
          <Title
              className="text-center"
              style={{ color: theme.palette.secondary.main, fontSize: 24, fontWeight: 'bold' }}
            >
              {formatRupiah(data.price)}
            </Title>
        </div>
        <div
          className="text-center mt-5 mb-2 w-full"
          style={{ marginBottom: 10 }}
        >
          <div className="text-center mt-8">
            <span className="font-secondary text-uppercase text-white">
              Detail Transaksi:
            </span>
            <div style={{ color: theme.palette.secondary.main }}>
              <div className="flex justify-between">
                <span className="whitespace-nowrap">ID Transaksi:</span>
                <div
                  style={{
                    width: "100%",
                    borderBottom: `1px solid ${theme.palette.secondary.main}`,
                    marginLeft: 5,
                    marginRight: 5,
                    marginBottom: 7
                  }}
                ></div>
                <span className="whitespace-nowrap">
                  {data.codeTransaction}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center mt-12" style={{marginBottom: 125}}>
          <Button variant="outlined" size="small" disabled={disabledBtn} onClick={() => setShowDialogCancel(true)}>
            <CloseIcon/>
            Batalkan Transaksi
          </Button>
        </div>
        <div className="mt-4 content-footer">
          <Button disabled={disabledBtn} onClick={() => checkPaymentStatus()}>
            Konfirmasi Pembayaran
          </Button>
        </div>
      </div>
      <Dialog visible={showDialogPending} onClose={handleClose}>
        <div className="text-center p-10">
          <SecondaryText>Status Transaksi</SecondaryText>
          <Card className="mt-4 flex flex-col justify-center" style={{width: '100%'}}>
            <img src="/assets/belummasuk.png" alt="qris" style={{ width: 'auto' }}/>
          </Card>
          <div className="mt-5">
            <p className="font-primary text-2xl" style={{color: theme.palette.secondary.main}}>
              Belum ada dana yang masuk, silahkan menunggu beberapa saat dan konfirmasi lagi
            </p>
          </div>
          <div className="mt-4">
            <Button disabled={disabledBtn} onClick={() => checkPaymentStatus()}>
              Konfirmasi Lagi
            </Button>
          </div>
        </div>
      </Dialog>

      <Dialog visible={showDialogCancel} onClose={handleCloseCancel}>
        <div className="text-center p-3">
          <SecondaryText>Batalkan Transaksi</SecondaryText>
          {/* <Card className="mt-4 flex flex-col justify-center" style={{width: '100%'}}>
            <img src="/assets/belummasuk.png" alt="qris" style={{ width: 'auto' }}/>
          </Card> */}
          <div className="mt-5">
            <p className="font-primary text-xl" style={{color: theme.palette.secondary.main}}>
              Anda yakin ingin membatalkan transaksi ini ?
            </p>
          </div>
          <div className="mt-4">
            <Button style={{width: '100%'}} disabled={disabledBtnCancel} onClick={() => cancelTransaction()}>
              Batalkan
            </Button>
          </div>
        </div>
      </Dialog>
    </LayoutStandard>
  );
}

export default Payment;
