import { useEffect } from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import { Helmet, HelmetProvider } from "react-helmet-async";
import theme from "./theme";
import history from "./history";
import NotFound from "pages/NotFound";

import useScrollTop from "hooks/useScrollTop";
import token from "utils/session";
import { useDispatch } from "react-redux";
// import { user_login } from "redux/reducers/users";
// import { autoSignIn } from "redux/reducers/auth";
import ProtectRoute from "components/ProtectRoute";
import Sidebar from "components/Navigation/Sidebar";

import Home from "pages/Home";
import Payment from "pages/Payment";
import PaymentSuccess from "pages/Payment/pages/PaymentSuccess";
import PaymentFailed from "pages/Payment/pages/PaymentFailed";
import TakePicture from "pages/TakePicture";


import Login from "pages/Auth/Login"
import ChooseBooth from "pages/Auth/ChooseBooth"

import Configuration from "pages/Configuration"

function App() {
  let route = (
    <ThemeProvider theme={theme}>
      <HelmetProvider>
        <BrowserRouter>
          <Helmet
            titleTemplate="%s - Photomatics Payment Portal"
            defaultTitle="Photomatics Payment Portal"
          >
            <meta name="description" content="Photomatics Payment Portal" />
          </Helmet>

          <Switch>
            <Route exact history={history} path="/login" component={Login} />
            <Route exact history={history} path="/" component={Home} />
            <Route exact history={history} path="/choose-booth" component={ChooseBooth} />
            <Route exact history={history} path="/configuration" component={Configuration} />
            <Route exact history={history} path="/payment" component={Payment} />
            <Route exact history={history} path="/payment/success" component={PaymentSuccess} />
            <Route exact history={history} path="/payment/failed" component={PaymentFailed} />
            <Route exact history={history} path="/takepicture" component={TakePicture} />
            <Route component={NotFound} />
          </Switch>

          {/* <Additions />
          <Sidebar /> */}
          {/* <Chat /> */}
        </BrowserRouter>
      </HelmetProvider>
    </ThemeProvider>
  );

  return <>{route}</>;
}

export default App;
