import { createSlice } from "@reduxjs/toolkit";
import { LOGIN, SET_LOADING } from "services/auth";
import session from "utils/session";

const initialState = {
  accessToken: "",
  displaySiginIn: false,
  login: {
    loading: false,
    accessToken: null,
  },
  message: "",
  profile: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    displayModalSiginIn: (state) => {
      state.displaySiginIn = true;
    },
    hideModalSignIn: (state) => {
      state.displaySiginIn = false;
    },
    logout: (state, { payload }) => {
      state.accessToken = "";
    },
    setLoading: (state, { payload }) => {
      state[payload.stateName].loading = payload.value;
      state.message = "";
    },
    setLogin: (state, { payload }) => {
      console.log("🚀 ~ file: auth.js ~ line 33 ~ payload", payload)
      state.login.loading = false;
      state.login.accessToken = payload.token;
      state.profile = payload.user;

      session.setToken(payload.token)
    },
    setProfile: (state, {payload}) => {
      state.profile = payload
    },
    setMessage: (state, { payload }) => {
      state.message = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoading, setLogin, setMessage, setProfile } = authSlice.actions;

export default authSlice.reducer;
