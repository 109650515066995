import { configureStore } from "@reduxjs/toolkit";
import user from "./reducers/users";
import auth from "./reducers/auth";
import global from './reducers/global'
import payment from './reducers/payment'

export const store = configureStore({
  reducer: {
    user,
    auth,
    global,
    payment,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    immutableCheck: { warnAfter: 128 },
    serializableCheck: { warnAfter: 128 },
  })
});
