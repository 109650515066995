import Axios from "axios";
import session from "./session";
import {store} from './../redux/store'
import { get } from 'lodash';

export function requestApiDesktop(config, hostname = null, port = null, isHttps = true) {
  const hostnameAPI = hostname || window.localStorage.getItem("HOSTNAME_APP");
  const portAPI = port || window.localStorage.getItem("PORT_APP");

  const baseUrlDesktop = `${isHttps ? 'https' : 'http'}://${hostnameAPI}:${portAPI}`;
  const fullurl = config.url
    ? `${baseUrlDesktop}${config.url}`
    : baseUrlDesktop;
  return Axios({
    timeout: 1000,
    ...config,
    url: fullurl,
  });
}

const axios = Axios.create({
  timeout: 5000 * 40,
});

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  const {response} = error;
  if (401 === get(error, 'response.status') && window.location.pathname !== '/login') {
      window.localStorage.clear();
      window.location.reload();
  } else {
      return Promise.reject(error);
  }
});

export function request(url, config = {}) {
  const mergeConfig = {
    
    method: 'GET',
    url,
    ...config,
    headers: {
      Authorization: session.getToken() ? `Bearer ${session.getToken()}`  : '',
      ...config.headers,
    }
  }
  
  return axios(mergeConfig)
}

export default axios;
