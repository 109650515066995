import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import session from 'utils/session'
import { useHistory } from 'react-router-dom'
import { getProfile } from 'services/auth'

export default function useAuth() {
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.auth)
  const history = useHistory();

  useEffect(() => {
    if(!session.getToken()) {
      history.push('/login');
    } 
  }, [])

  useEffect(() => {
    if(session.getToken() && !profile) {
      dispatch(getProfile())
    }
  }, [profile])
  
  return {
    user: profile
  }
}