/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Button from "components/Button";
import { Title } from "components/Typography";
import LayoutStandard from "components/Layout/Standard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box } from "@material-ui/core";
import { SecondaryText } from "components/Typography";
import TextField from "components/TextField";
import {
  useTheme,
} from "@material-ui/styles";
import { Link } from "react-router-dom";
import Dialog from "components/Dialog";
import endpoints from "utils/endpoint";
import { request } from "utils/request";
import useAuth from "hooks/useAuth";
import useBooth from "hooks/useBooth";
import { useFormik } from "formik";
import * as Yup from "yup";
import session from "utils/session";

function Configuration() {
  useAuth();
  const booth = useBooth();
  const theme = useTheme();
  const [isBoothConnnect, setIsBoothConnect] = useState(null);
  const [isBoothReachable, setIsBoothReachable] = useState(false);
  const [message, setMessage] = useState("");
  const [dialogKonfigurasi, setDialogKonfigurasi] = useState(false);
  const [dialogBlock, setDialogBlock] = useState(false);

  const selectedBooth = booth.getSelected();

  const testConnection = () => {
    setMessage("Menghubungkan...");
    booth
      .isConnected()
      .then((result) => {
        setIsBoothConnect(true);
        setMessage(result.message);
        setIsBoothReachable(result.reachable);
      })
      .catch((result) => {
        setIsBoothConnect(false);
        setMessage(result.detail || result.message);
        setIsBoothReachable(result.reachable);
      });
  };

  useEffect(() => {
    if (isBoothConnnect === null) {
      testConnection();
    }
  }, [isBoothConnnect]);

  const formik = useFormik({
    initialValues: {
      ip: selectedBooth?.ip || "",
      port: selectedBooth?.port || 3312,
      branch_id: selectedBooth?.branch_id,
      name: selectedBooth?.name,
    },
    validationSchema: Yup.object().shape({
      ip: Yup.string().required(),
      port: Yup.number().required(),
    }),
    onSubmit(values) {
      request(`${endpoints.booth.update}${selectedBooth.id}`, {
        method: "PATCH",
        data: values,
      })
        .then((response) => {
          const { data } = response.data;
          booth.setSelected(data);
          testConnection();
          setDialogKonfigurasi(false);
        })
        .catch((err) => {
          window.alert("Update Failed");
        });
    },
  });

  const { touched, errors, getFieldProps } = formik;

  const handleClickLogout = () => {
    session.clear();
    window.location.replace('/login')
  }
  
  function openFullscreen() {
    var elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) { /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE11 */
      elem.msRequestFullscreen();
    }
  }

  function exitFullscreen() {
    if (document.exitFullscreen) 
        document.exitFullscreen()
    else { 
          if (document.mozCancelFullScreen) 
              document.mozCancelFullScreen()
          else{
              if (document.webkitCancelFullScreen) 
                  document.webkitCancelFullScreen();
          }
      }
  }
  
  return (
    <LayoutStandard>
      <div className="mt-3">
        <Button
          component={Link}
          to="/"
          type="button"
          variant="outlined"
          size="small"
          style={{ minWidth: "90px" }}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.21866 7.33312H13.3333V8.66645H5.21866L8.79466 12.2425L7.85199 13.1851L2.66666 7.99979L7.85199 2.81445L8.79466 3.75712L5.21866 7.33312Z"
              fill="#FFF3B7"
            />
          </svg>
          Kembali
        </Button>
      </div>
      <div className="flex flex-col items-center justify-center">
        <div className="text-center mb-7">
          <SecondaryText>Status</SecondaryText>
          <Title style={{ color: theme.palette.secondary.main }}>
            {message}
          </Title>
        </div>
        <div className="text-center mb-7 w-full">
          <SecondaryText>IP & Port</SecondaryText>
          <Title style={{ color: theme.palette.secondary.main }}>{`${
            selectedBooth?.ip || "-"
          }:${selectedBooth?.port || "-"}`}</Title>
          <Button
            onClick={() => setDialogKonfigurasi(true)}
            type="button"
            variant="outlined"
            style={{ width: "100%" }}
          >
            Ubah IP & Port
          </Button>
          <Button
            onClick={() => openFullscreen()}
            type="button"
            variant="outlined"
            style={{ width: "100%", marginTop: "10px" }}
          >
            Fullscreen
          </Button>
          
          <Button
            onClick={() => exitFullscreen()}
            type="button"
            variant="outlined"
            style={{ width: "100%", marginTop: "10px" }}
          >
            Exit Fullscreen
          </Button>
        </div>
        <div className="w-full">
          <hr style={{ width: "100%", backgroundColor: "#FFF3B7" }} />
        </div>
        <div className="mt-5 text-center w-full">
          <SecondaryText>Pengaturan Booth</SecondaryText>
          {/* <Button
            disabled={!isBoothReachable}
            type="button"
            style={{ width: "100%", marginTop: "10px" }}
            onClick={() => booth.downloadCertificate()}
          >
            <svg
              width="27"
              height="26"
              viewBox="0 0 27 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.5833 10.8333H20L13.5 17.3333L6.99999 10.8333H12.4167V3.25H14.5833V10.8333ZM4.83332 20.5833H22.1667V13H24.3333V21.6667C24.3333 21.954 24.2192 22.2295 24.016 22.4327C23.8129 22.6359 23.5373 22.75 23.25 22.75H3.74999C3.46267 22.75 3.18712 22.6359 2.98396 22.4327C2.78079 22.2295 2.66666 21.954 2.66666 21.6667V13H4.83332V20.5833Z"
                fill="white"
              />
            </svg>
            Sertifikat
          </Button> */}
          <Button
            onClick={() => testConnection()}
            type="button"
            variant="outlined"
            style={{ width: "100%", marginTop: "10px" }}
          >
            Test Koneksi
          </Button>
          {/* <Button
            disabled={!isBoothConnnect}
            type="button"
            variant="outlined"
            style={{ width: "100%", marginTop: "10px" }}
            onClick={() => setDialogBlock(true)}
          >
            Block/Unblock
          </Button> */}
        </div>
        
        <div className="w-full mt-4">
          <hr style={{ width: "100%", backgroundColor: "#FFF3B7" }} />
        </div>
        <div className="w-full mt-4">
          <Button
            onClick={() => handleClickLogout()}
            type="button"
            variant="contained"
            style={{ width: "100%", marginTop: "10px", backgroundColor: '#602413', borderColor: '#FFF3B7' }}
          >
            Log-out
          </Button>
        </div>
      </div>
      <Dialog
        visible={dialogKonfigurasi}
        onClose={() => setDialogKonfigurasi(false)}
      >
        <form onSubmit={formik.handleSubmit}>
          <div
            style={{
              padding: 10,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <SecondaryText>Edit IP & Port</SecondaryText>
            <div style={{ marginBottom: 15 }}>
              <TextField
                label="IP"
                error={Boolean(touched.ip && errors.ip)}
                errorText={errors.ip}
                {...getFieldProps("ip")}
              />
            </div>
            <div style={{ marginBottom: 15 }}>
              <TextField
                type="number"
                label="Port"
                error={Boolean(touched.port && errors.port)}
                errorText={errors.port}
                {...getFieldProps("port")}
              />
            </div>
            <Button
              type="button"
              variant="outlined"
              style={{ padding: "10px", minWidth: "175px" }}
              onClick={() => setDialogKonfigurasi(false)}
            >
              Batalkan
            </Button>
            <Button
              type="submit"
              style={{ padding: "10px", minWidth: "175px", marginTop: 7 }}
            >
              Simpan
            </Button>
          </div>
        </form>
      </Dialog>

      <Dialog visible={dialogBlock} onClose={() => setDialogBlock(false)}>
        <div
          style={{
            padding: 10,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <SecondaryText>Blok / Unblok komputer</SecondaryText>
          <div style={{ marginBottom: 15 }}>
            <div style={{color: '#FFF3B7'}}>
              PERHATIAN:<br/>
              Cek kondisi komputer yang sebenarnya untuk mengetahui kondisi sedang blok atau unblok
            </div>
          </div>
          <Button
            type="button"
            style={{ padding: "10px", minWidth: "175px" }}
            onClick={() => booth.trigger('block')}
          >
            Blok
          </Button>
          <Button
            type="button"
            style={{ padding: "10px", minWidth: "175px", marginTop: 10 }}
            onClick={() => booth.trigger('unblock')}
          >
            Unblok
          </Button>
            <Button
              type="button"
              style={{ padding: "10px", minWidth: "175px", marginTop: 35, }}
              variant="outlined"
              onClick={() => setDialogBlock(false)}
            >
              Tutup
            </Button>
        </div>
      </Dialog>
    </LayoutStandard>
  );
}

export default Configuration;
