import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import endpoint from "utils/endpoint";
import token from "utils/session";

const initialState = {
  isLoad: false,
  isError: false,
  error: null,
  is_login: false,
  profile: null,
  vendor: {
    data: [],
  },
};

const login = createSlice({
  name: "LOGIN",
  initialState,
  reducers: {
    user_login: (state, action) => {
      state.is_login = action.payload.status;
    },
    getProfileSuccess: (state, action) => {
      state.profile = action.payload;
    },
    getMyVendorSuccess: (state, action) => {
      state.vendor = action.payload;
      state.isLoad = false;
    },
    startLoading: (state) => {
      state.isLoad = true;
    },
    hasError: (state, action) => {
      state.isError = true;
      state.error = action.payload;
    },
  },
});

export const { user_login, getProfileSuccess } = login.actions;

export default login.reducer;

export function getMyVendor(params = { page: 1, page_size: 3 }) {
  return async (dispatch, getState) => {
    const url = endpoint.search;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        // user_id: userId,
        ...params,
      },
    };

    dispatch(login.actions.startLoading());

    try {
      const response = await axios(url, config);
      dispatch(login.actions.getMyVendorSuccess(response.data));
    } catch (error) {
      dispatch(login.actions.hasError(error.response));
    }
  };
}
