import React from "react";
import { Drawer } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useDispatch, useSelector } from "react-redux";
// import { actionsGlobal } from "../../redux/reducers/global";
import classnames from "classnames";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: 250,
      height: "100vh",
      maxHeight: "100vh",
    },
  })
);

const menu = [
  {
    label: "Cari pelaku usaha",
    href: "/search",
  },
  {
    label: "Statistik",
    href: "/statistics",
  },
  {
    label: "Informasi PBJP",
    href: "/info_pbjp",
  },
  {
    label: "Tentang Kami",
    href: "/about_us",
  },
];

export default function Sidebar() {
  const dispatch = useDispatch();
  const { isSidebarOpen } = useSelector((state) => state.global);

  const classes = useStyles();

  return (
    <Drawer
      anchor="left"
      open={isSidebarOpen}
      // onClose={() => dispatch(actionsGlobal.closeSidebar())}
    >
      <div className={classnames(classes.root, "bg-pink-header")}>
        <ul className="py-4">
          <NavLink
            to="/"
            className="py-4 px-4 text-base text-white font-montserrat block"
            activeClassName="font-bold"
            isActive={(match, location) => {
              return match.isExact;
            }}
            // onClick={() => dispatch(actionsGlobal.closeSidebar())}
          >
            Beranda
          </NavLink>
          {menu.map((_item, index) => (
            <NavLink
              to={_item.href}
              className="py-4 px-4 text-base text-white font-montserrat block"
              // activeClassName="font-bold"
              // onClick={() => dispatch(actionsGlobal.closeSidebar())}
            >
              {_item.label}
            </NavLink>
          ))}
        </ul>
      </div>
    </Drawer>
  );
}
