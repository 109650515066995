const palette = {
  primary: {
    main: "#9D2605",
  },
  secondary: {
    main: "#FFF3B7",
  },
  success: {
    main: "#54D62C",
  },
  grey: {
    200: "#F2F2F2",
    500: "#ABABAB",
  },
};

export default palette;
