import React from 'react'
import PropTypes from 'prop-types'
import { Dialog, IconButton } from '@material-ui/core'
import {
  makeStyles,
  createStyles,
  styled,
  useTheme,
} from "@material-ui/styles";
import { Close } from '@material-ui/icons';

const useStyles = makeStyles((theme) => 
createStyles({
  dialogPaper: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
  },
  dialogPaperNotFullScreen: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    borderRadius: 16
  }
}));

export default function Modal(props) {
  const theme = useTheme();
  const { visible, onClose, children, ...others } = props
  const classes = useStyles();

  const dialogStyle = {width: '100vw', height: '100vh'};

  if(others.fullScreen) {
    dialogStyle.borderRadius = 15
  }
  
  return (
    <Dialog classes={{paper: others.fullScreen ? classes.dialogPaper : classes.dialogPaperNotFullScreen}} style={dialogStyle} open={visible} onClose={onClose} {...others}>
      <div className="text-right">
        <IconButton style={{color: theme.palette.secondary.main}}  onClick={onClose}>
          <Close/>
        </IconButton>
      </div>
      {children}
    </Dialog>
  )
}

Modal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
}