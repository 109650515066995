import { Box } from "@material-ui/core";
import { makeStyles, createStyles, useTheme } from "@material-ui/styles";
import classNames from "classnames";
import { Title } from "components/Typography";
import TextField from "components/TextField"
import Button from "components/Button"
import { useFormik } from "formik";
import * as Yup from 'yup'
import { doLogin } from 'services/auth'
import './Login.css'
import { useDispatch, useSelector } from 'react-redux'
import { Alert } from "@material-ui/lab";
import { useEffect } from "react";
import session from "utils/session";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      // marginTop: 110,
      backgroundColor: theme.palette.primary.main,
      height: '100vh',
      textAlign: 'center',
      paddingTop: '5vh',
      paddingLeft: '2vw',
      paddingRight: '2vw',
      alignItems: 'center',
      // justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',

    },
    scrollMargin: {
      scrollMarginTop: "120px",
    },
    image: {
      width: "100%",
      height: 516,
      objectFit: "cover",

      [theme.breakpoints.down("lg")]: {
        height: 230,
      },
    },
  })
);

function Login() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const {login, message} = useSelector((state) => state.auth);
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: Yup.object().shape({
      username: Yup.string().required(),
      password: Yup.string().required(),
    }),
    onSubmit(values) {
      dispatch(doLogin(values)).then(() => {
        history.push('/')
      })
    }
  })

  const { touched, errors, getFieldProps, handleSubmit } = formik
  
  useEffect(() => {
    if(session.getToken()) {
      history.push('/');
    }
  }, [])
  
  return (
    <div className="login-container">
      <Box className={classNames(classes.root, "font-primary")}>
        <div>
          <img className="img-logo" src="/assets/logo.png" alt="Logo Photomatics" />
        </div>
        <hr />
        <Title style={{ color: theme.palette.secondary.main }}>Payment Portal</Title>
        <form onSubmit={handleSubmit}>
          {message && (
            <Alert severity="error">{message}</Alert>
          )}
          <div className="mb-5">
            <TextField 
              label="Username" 
              name="username"
              error={Boolean(touched.username && errors.username)}
              errorText={errors.username}
              {...getFieldProps('username')}
            />
            <TextField 
              type="password"
              label="Password" 
              name="password"
              error={Boolean(touched.password && errors.password)}
              errorText={errors.password}
              {...getFieldProps('password')}
            />
          </div>
          <div className="mt-2">
            <Button type="submit" disabled={login.loading}>Masuk Portal</Button>
          </div>
        </form>
      </Box>
    </div>
  )
}

export default Login