/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react";
import Footer from "components/footer";
import Header from "components/header";
import Button from "components/Button";
import {Title} from "components/Typography";
import LayoutStandard from "components/Layout/Standard";
import Card from "components/Card";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box } from "@material-ui/core";
import {
  makeStyles,
  createStyles,
  styled,
  useTheme,
} from "@material-ui/styles";
import classNames from "classnames";
import { Link, useHistory } from "react-router-dom";
import * as paymentService from 'services/payment';
import { useDispatch } from "react-redux";
import { createTransactionSuccess } from "redux/reducers/payment";
import endpoints from 'utils/endpoint';
import axios, { request } from "utils/request";
import useBooth from 'hooks/useBooth'
import {get} from 'lodash'


function TakePicture() {
  const history = useHistory();
  const [idInterval, setIdInterval] = useState(null);
  const booth = useBooth();
  const [trxActive, setTrxActive] = useState(null);
  const theme = useTheme();


  useEffect(() => {
    booth.trigger('unblock')
  }, [])

  useEffect(() => {
    if(!idInterval) {
      const id  = window.setInterval(() => {
        request(endpoints.booth.detail+booth.getSelected().id).then(resp => {
          const {data} = resp.data
          if(data.progress_status === 'session_end') {
            history.push('/');
            window.clearInterval(id);

            setTimeout(() => {
              setIdInterval(null);
            }, 1000)
          }
        })
      }, 1000)

      setIdInterval(id);
    }
  }, [idInterval])
  
  useEffect(() => {
    if (!window.localStorage.getItem("TRX_ACTIVE")) {
      window.localStorage.setItem(
        "TRX_ACTIVE",
        window.localStorage.getItem("TRX_CURRENT")
      );
    }

    window.localStorage.removeItem("TRX_CURRENT");
    window.localStorage.removeItem("TIMER_VALUE");

    setTrxActive(JSON.parse(atob(window.localStorage.getItem("TRX_ACTIVE"))));
  }, []);
  
  return (
    <LayoutStandard>
      <div className="flex flex-col items-center justify-center">
        <img
          src="/assets/photobooth.png"
          alt="photobooth"
          style={{ height: 232 }}
        />
        <div className="flex flex-col justify-center">
            <Card>
                <img src="/assets/takepicture.png" alt="takepicture"/>
            </Card>
            <div className="text-center mt-8">
              <span className="font-secondary text-uppercase text-white">
                Detail Transaksi:
              </span>
              <div style={{ color: theme.palette.secondary.main }}>
                <div className="flex justify-between">
                  <span className="whitespace-nowrap">ID Transaksi:</span>
                  <div
                    style={{
                      width: "100%",
                      borderBottom: `1px solid ${theme.palette.secondary.main}`,
                      marginLeft: 5,
                      marginRight: 5,
                      marginBottom: 7
                    }}
                  ></div>
                  <span className="whitespace-nowrap">
                    {get(trxActive, 'code_transaction')}
                  </span>
                </div>
              </div>
            </div>
        </div>
      </div>
    </LayoutStandard>
  );
}

export default TakePicture;
