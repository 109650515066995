import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, createStyles, useTheme, styled, withTheme } from "@material-ui/styles";
import { Box } from "@material-ui/core";
import classNames from "classnames";
import { get } from 'lodash'
import useBooth from 'hooks/useBooth'
import {useHistory} from 'react-router-dom'
import { SecondaryText } from "components/Typography";
import Button from "components/Button";
import {closeDialogSetting, openDialogSetting} from '../../redux/reducers/global'
import Dialog from "components/Dialog";
import TextField from "components/TextField";
import { request } from "utils/request";
import endpoints from 'utils/endpoint'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) =>
     createStyles({
      root: {
        backgroundColor: theme.palette.primary.main,
        height: 72
      },
    })
);

const LocationBox = styled('div')(({theme}) => ({
  backgroundColor: theme.palette.secondary.main,
  height: '45px',
  padding: '0 20px',
}))

const Text = styled('div')(({theme}) => ({
  width: '100%',
  textAlign: 'center',
  borderBottom: `1px solid ${theme.palette.primary.main}`,
  lineHeight: '0.1em',
  // margin: '10px 0 20px', 
  backgroundColor: theme.palette.secondary.main,
  paddingTop: 11
}))

function Header(props) {
  const theme = useTheme();
  const {profile} = useSelector((state) => state.auth);
  const {isDialogSettingOpen} = useSelector((state) => state.global)
  const [totalToday, setTotalToday] = useState(0)

  const history = useHistory();
  const dispatch = useDispatch();
  const booth = useBooth();
  const inputRef = useRef();
  
  if(!booth.getSelected()) {
    history.push('/choose-booth')
  }
  
  const classes = useStyles();
  
  const handleCekPassword = () => {
    const val = inputRef.current.value;
    request(endpoints.auth.verifyPassword, {
      method: 'POST',
      data: {
        password: val
      }
    }).then((response) => {
      const { status } = response.data
      if(status) {
        history.push('/configuration')
        dispatch(closeDialogSetting())
      } else {
        inputRef.current.value = ""
      }
      
    })
  }

  const buttonStyle = {
    backgroundColor: '#602413',
    borderRadius: '50%',
    width: 32,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 7
  }
  
  const divStyle = {
    backgroundColor: '#602413',
    borderRadius: 16,
    height: 32,
    color: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
    minWidth: 147,
  }
  
  useEffect(() => {
    request(endpoints.transaction.total, {
      method: 'GET',
      params: {
        booth_id: booth.getSelected()?.id,
      }
    }).then((resp) => {
      console.log("🚀 ~ file: index.js ~ line 108 ~ useEffect ~ resp", resp)
      const total = get(resp, 'data.total')
      setTotalToday(total);
      window.localStorage.setItem('TRX_TOTAL', total)
    })
  }, [])
  
  return (
    <header>
      <Box className={classNames(classes.root)}>
        <div className="flex p-2 items-center justify-between">
          <div>
            <Link to="/">
              <img src="/assets/logo.png" alt="Logo Photomatics" style={{height: '40px'}} />
            </Link>
          </div>
          <div className="flex items-center" style={{gap: 10}}>
            <div style={divStyle}>
              Total hari ini: <span style={{display: 'inline-block', marginLeft: 5}}>{totalToday}</span>
            </div>
            <button className="" style={buttonStyle} type="button" onClick={() => dispatch(openDialogSetting())}>
              <img src="/assets/icon-star-button.png" alt=""  />
            </button>
            
          <Dialog
            visible={isDialogSettingOpen}
            onClose={() => dispatch(closeDialogSetting())}
          >
            <div
              style={{
                padding: 10,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <SecondaryText>Pengaturan Booth</SecondaryText>
              <div style={{ marginBottom: 15 }}>
                <TextField
                  label="Password"
                  type="password"
                  ref={inputRef}
                />
              </div>
              <Button
                type="button"
                variant="outlined"
                style={{ padding: "10px", minWidth: "175px" }}
                onClick={() => dispatch(closeDialogSetting())}
              >
                Batalkan
              </Button>
              <Button
                type="button"
                style={{ padding: "10px", minWidth: "175px", marginTop: 7 }}
                onClick={handleCekPassword}
              >
                Akses
              </Button>
            </div>
          </Dialog>
          </div>
        </div>
        <LocationBox>
          <Text>
            <span style={{background: theme.palette.secondary.main, padding: '0 10px', color: theme.palette.primary.main, fontWeight: 'bold'}}>
              {get(profile, 'branch.name')}
            </span>
          </Text>
          <div className="mt-2" style={{fontSize: '14px', textAlign: 'center', background: theme.palette.secondary.main, color: theme.palette.primary.main, fontWeight: 'bold'}}>
            {get(booth.getSelected(), 'name')}
          </div>
        </LocationBox>
      </Box>
    </header>
  );
}

export default Header;
