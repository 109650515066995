import React from 'react';
import { Button as ButtonMui } from "@material-ui/core";
import classNames from "classnames";

export default function Button(props) {
  const { style, className, variant, size, ...otherProps } = props;

  const combinedStyle = {
    backgroundColor: '#7FAA99',
    padding: '16px',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: '#426E5C',
    borderRadius: '28px',
    // fontWeight: 'bold',
    // fontStyle: 'italic',
    fontSize: '20px',
    color: otherProps.disabled ? 'rgb(233 224 224)' : '#fff',
    textAlign: 'center',
    textTransform: 'uppercase',
    ...style,
  }

  if(size === 'small') {
    combinedStyle.fontSize = '12px';
    combinedStyle.padding = '7px';
    combinedStyle.borderWidth = '1px';
  }

  if(otherProps.disabled) {
    combinedStyle.backgroundColor = 'rgb(178 182 181)'
  }

  if(variant === 'outlined') {
    combinedStyle.backgroundColor = '#9D2605';

    if(otherProps.disabled) {
      combinedStyle.color = "#ccc6a7";
      combinedStyle.borderColor = '#ccc6a7'
    } else {
      combinedStyle.color = "#FFF3B7";
      combinedStyle.borderColor = '#FFF3B7'
    }
  }
  

  const combinedClassName = classNames('font-secondary', className)
  
  return (
    <ButtonMui className={combinedClassName} style={combinedStyle} {...otherProps}>
      {props.children}
    </ButtonMui>
  )
}