/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import Footer from "components/footer";
import Header from "components/header";
import Button from "components/Button";
import { Title } from "components/Typography";
import LayoutStandard from "components/Layout/Standard";
import Card from "components/Card";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box } from "@material-ui/core";
import { SecondaryText } from "components/Typography";
import {
  makeStyles,
  createStyles,
  styled,
  useTheme,
} from "@material-ui/styles";
import classNames from "classnames";
import { Link, useHistory } from "react-router-dom";
import * as paymentService from "services/payment";
import { useDispatch, useSelector } from "react-redux";
import { createTransactionSuccess } from "redux/reducers/payment";
import Dialog from "components/Dialog";
import { TextField, Button as ButtonMui } from "@material-ui/core";
import endpoints from "utils/endpoint";
import axios, { requestApiDesktop } from "utils/request";
import useAuth from 'hooks/useAuth'
import useBooth from 'hooks/useBooth'
import { openDialogSetting } from "redux/reducers/global";
import { get } from 'lodash'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      // marginTop: 110,
      backgroundColor: theme.palette.primary.main,
      height: "100vh",
    },
    scrollMargin: {
      scrollMarginTop: "120px",
    },
    image: {
      width: "100%",
      height: 516,
      objectFit: "cover",

      [theme.breakpoints.down("lg")]: {
        height: 230,
      },
    },
  })
);

const Body = styled(Box)(({ theme }) => ({
  padding: "30px",
}));

window.triesCount = 1;

function Home() {
  const { user } = useAuth();
  const booth = useBooth();
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isBoothConnnect, setIsBoothConnect] = useState(null);
  const [showRefreshButton, setShowRefreshButton] = useState(false);
  
  const handleClick = () => {
    setLoading(true);
    paymentService.create({
      booth_id: booth.getSelected()?.id
    }, (response, isError) => {
      setLoading(false);
      if(!isError) {
        const {data} = response
        console.log("🚀 ~ file: index.js ~ line 79 ~ handleClick ~ data", data)
        dispatch(
          createTransactionSuccess({
            transactionId: data.id,
            qrContent: data.qr_content,
            price: data.price,
            codeTransaction: data.code_transaction,
            ...data,
          })
        );
  
        history.push("/payment");
      } else {
        window.alert('Failed')
      }
    });
  };
  
  const doRefresh = () => {
    booth.isConnected().then(() => {
      setIsBoothConnect(true);
      setShowRefreshButton(false);
    }).catch(() => {
      setIsBoothConnect(false);
      window.alert('Tidak terhubung');
    })
  }

  useEffect(() => {
    if(isBoothConnnect === null) {
      booth.isConnected().then(() => {
        setIsBoothConnect(true)
      }).catch(() => setIsBoothConnect(false))
    } else if(isBoothConnnect === false && !showRefreshButton && !window.idInterval) {
      // jika gagal konek & tombol refreh belum muncul
      // app payment akan coba konek ke app desktop dalam kurun waktu tertentu
      // jika 5x gagal maka akan memunculkan tombol refresh
      booth.isConnected().then(() => {
        setIsBoothConnect(true);
        window.triesCount = 0;
      }).catch(() => {
        window.idInterval = setInterval(() => {
          console.log('trying to connect to the desktop app...')
          booth.isConnected(false).then(() => {
            window.clearInterval(window.idInterval);
            window.idInterval = null;

            setIsBoothConnect(true);
            window.triesCount = 0;
            setShowRefreshButton(false);
            console.log('connected');
          }).catch(() => {
            setIsBoothConnect(false)
            window.triesCount = Number(window.triesCount) + 1;
            console.log('failed');

            if(Number(window.triesCount) > 5) {
              setShowRefreshButton(true);

              window.clearInterval(window.idInterval);
              window.idInterval = null;
            } else {
              setShowRefreshButton(false);
            }
          })
        }, 2000)
      })
    } else if(isBoothConnnect === true && !window.cekIdInterval) {
      // jika berhasil terhubung, app payment akan mengecek secara terus menerus 5detik sekali
      
      window.cekIdInterval = setInterval(() => {
        booth.isConnected(false).then(() => console.log('connected')).catch(() => {
          console.log('failed to connect to app desktop');
          setIsBoothConnect(false);

          // clear interval cek
          window.clearInterval(window.cekIdInterval);
          window.cekIdInterval = null;
        })
      }, 5000)
    }
  }, [isBoothConnnect]);

  useEffect(() => {
    return () => {
      // jika keluar dari page ini, reset semua interval
      window.clearInterval(window.idInterval);
      window.idInterval = null;

      window.clearInterval(window.cekIdInterval);
      window.cekIdInterval = null;
    }
  }, [])
  
  return (
    <LayoutStandard>
      <div className="flex flex-col items-center justify-center">
        <div className="mt-5 text-center flex flex-col items-center">
          <img
            src="/assets/photobooth.png"
            alt="photobooth"
            style={{ height: 232, width: 'fit-content' }}
          />
          <div className="flex flex-col justify-center">
            <Title
              className="text-center"
              style={{ color: theme.palette.secondary.main, fontWeight: 'bold' }}
            >
              Mesin Pengambil<br/>Gambar Otomatis
            </Title>
            <div className="text-center mt-8">
              <div className="mb-5">
                <span className="font-secondary text-uppercase text-white">
                  Cabang:
                </span>
                <Title
                  className="text-center"
                  style={{ color: theme.palette.secondary.main, fontSize: 24, fontWeight: 'bold' }}
                >
                  {get(user, 'branch.name')}<br/>
                  {booth.getSelected()?.name}
                </Title>
              </div>
              {isBoothConnnect && (
                <Button disabled={loading} onClick={() => handleClick()}>
                  MULAI DAFTAR
                </Button>
              )}
              {isBoothConnnect === false && (
                <div class="text-center">
                  <div>
                    <Button variant="outlined" onClick={() => dispatch(openDialogSetting())}>
                      Hubungkan
                    </Button>
                  </div>

                  {showRefreshButton && (
                    <div className="mt-5">
                      <Button size="small" variant="outlined" onClick={() => doRefresh()}>
                        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M4.14204 2.95501C5.35175 1.90679 6.89937 1.33078 8.50004 1.33301C12.182 1.33301 15.1667 4.31768 15.1667 7.99968C15.1667 9.42368 14.72 10.7437 13.96 11.8263L11.8334 7.99968H13.8334C13.8335 6.9541 13.5262 5.93155 12.9498 5.05918C12.3734 4.18682 11.5533 3.50312 10.5915 3.0931C9.62966 2.68307 8.56852 2.56482 7.54001 2.75303C6.51151 2.94124 5.56101 3.42761 4.80671 4.15168L4.14204 2.95501ZM12.858 13.0443C11.6483 14.0926 10.1007 14.6686 8.50004 14.6663C4.81804 14.6663 1.83337 11.6817 1.83337 7.99968C1.83337 6.57568 2.28004 5.25568 3.04004 4.17301L5.16671 7.99968H3.16671C3.16662 9.04526 3.47388 10.0678 4.05027 10.9402C4.62665 11.8125 5.44675 12.4962 6.40859 12.9063C7.37042 13.3163 8.43156 13.4345 9.46007 13.2463C10.4886 13.0581 11.4391 12.5717 12.1934 11.8477L12.858 13.0443Z" fill="#FFF3B7"/>
                        </svg>
                        Refresh
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </LayoutStandard>
  );
}

export default Home;
