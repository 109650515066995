import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Link as RouterLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      minHeight: "100vh",
      backgroundImage: 'url("/static/images/error-page-content.jpg")',
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% 100%",
    },
    label: {
      color: theme.palette.grey[500],
      fontFamily: "Baloo 2",
      fontWeight: 700,
    },
    description: {
      color: "#595959",
      fontWeight: 700,
      marginBottom: 20,
      marginTop: 15,
    },
    info: {
      color: "#595959",
      fontWeight: 500,
    },
  })
);

export default function NotFound() {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <div className="flex flex-col justify-center items-center min-h-screen">
        <div className="mb-5">
          <svg
            width="120"
            height="120"
            viewBox="0 0 120 120"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M53.9317 7.71484C66.626 7.71484 78.1117 12.8577 86.426 21.172C94.7403 29.4863 99.8831 40.9806 99.8831 53.6663C99.8831 62.9491 97.1317 71.5892 92.3917 78.8234L112.277 100.501L98.5631 113.041L79.3888 91.9377C71.8529 96.9685 62.9925 99.6475 53.9317 99.6349C41.2374 99.6349 29.7517 94.492 21.4374 86.1777C13.1145 77.8463 7.97168 66.3606 7.97168 53.6749C7.97168 40.9806 13.1145 29.4949 21.4288 21.172C29.7517 12.8577 41.2374 7.71484 53.9317 7.71484ZM60.7974 41.0663C61.1748 40.6813 61.6249 40.3752 62.1217 40.1656C62.6184 39.9561 63.1518 39.8474 63.6909 39.8458C64.2301 39.8442 64.7641 39.9498 65.2621 40.1563C65.7601 40.3629 66.212 40.6664 66.5917 41.0491C67.3623 41.8274 67.796 42.8775 67.7992 43.9728C67.8025 45.068 67.3749 46.1206 66.6088 46.9034L59.606 54.0091L66.6174 61.1234C68.2031 62.7349 68.1774 65.3406 66.5745 66.9434C64.9631 68.5463 62.3745 68.5463 60.7974 66.9349L53.8288 59.872L46.8517 66.952C46.4743 67.337 46.0241 67.6431 45.5274 67.8527C45.0307 68.0622 44.4972 68.1709 43.9581 68.1725C43.419 68.1741 42.885 68.0685 42.387 67.862C41.889 67.6554 41.4371 67.3519 41.0574 66.9691C40.2868 66.1909 39.853 65.1408 39.8498 64.0455C39.8466 62.9503 40.2742 61.8976 41.0403 61.1149L48.0431 54.0006L41.0317 46.8863C39.446 45.2749 39.4717 42.6691 41.0831 41.0663C42.6945 39.4634 45.2831 39.4634 46.8603 41.0749L53.8288 48.1377L60.7974 41.0663V41.0663ZM82.8003 24.7977C75.4117 17.4091 65.2031 12.8406 53.9317 12.8406C42.6603 12.8406 32.4517 17.4091 25.0631 24.7977C17.666 32.1863 13.0974 42.3949 13.0974 53.6749C13.0974 64.9463 17.666 75.1549 25.0545 82.5434C32.4431 89.932 42.6517 94.5006 53.9231 94.5006C65.2031 94.5006 75.4031 89.932 82.7917 82.5434C90.1803 75.1549 94.7488 64.9463 94.7488 53.6749C94.7574 42.3949 90.1888 32.1863 82.8003 24.7977Z"
              fill="#DE1B51"
            />
          </svg>
        </div>

        <Typography variant="h3" className={classes.label}>
          Error 404: Not Found
        </Typography>

        <Typography variant="p" className={classes.description}>
          The server understood the request, but is refusing to fulfill it
          because the requested resource no longer exists or has been moved.
        </Typography>

        <Typography variant="p" className={classes.info}>
          Silakan coba kembali beberapa saat lagi, atau hubungi call center LKPP
          (021) 29935777 | 144.
        </Typography>

        <Typography variant="p" className={classes.info}>
          Atau hubungi lewat email:{" "}
          <Typography
            variant="a"
            component="a"
            color="primary"
            href="mailto:direktori.lkpp@gmail.com"
          >
            direktori.lkpp@gmail.com
          </Typography>
        </Typography>

        <div className="mt-5">
          <Typography variant="p" className={classes.info}>
            Klik di sini untuk kembali ke halaman sebelumnya.
          </Typography>
        </div>

        <div className="mt-2">
          <Button
            variant="contained"
            color="primary"
            component={RouterLink}
            to="/"
          >
            Kembali ke Beranda
          </Button>
        </div>
      </div>
    </Box>
  );
}
