export function convertObjectToFormData(planObject) {
  const formData = new FormData();

  Object.keys(planObject).forEach(name => {
    let value = planObject[name];

    if(value === null || value === undefined) {
      value = "";
    }
    
    formData.append(name, value);
  })

  return formData;
}

export function formatRupiah(angka, prefix = true) {
  const number_string = angka.toString().replace(/[^,\d]/g, '').toString();
  const split = number_string.split(',');
  const sisa = split[0].length % 3;
  let rupiah = split[0].substr(0, sisa);
  const ribuan = split[0].substr(sisa).match(/\d{3}/gi);

  if (ribuan) {
    const separator = sisa ? '.' : '';
    rupiah += separator + ribuan.join('.');
  }

  rupiah = split[1] != undefined ? `${rupiah},${split[1]}` : rupiah;
  return prefix == undefined ? rupiah : rupiah ? `Rp ${rupiah}` : '';
}