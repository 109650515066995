import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { makeStyles, createStyles, styled } from "@material-ui/styles";
import classNames from "classnames";
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      // marginTop: 110,
      backgroundColor: theme.palette.secondary.main,
      borderRadius: 28,
      border: '2px solid #242322',
      padding: '16px 16px',
    },
  })
);
function Cards(props) {
  const classes = useStyles();
  const {className, ...otherProps} = props;

  return (
    <Box className={classNames(classes.root, "font-montserrat", className)} {...otherProps}>
      {props.children}
    </Box>
  );
}

export default Cards;
