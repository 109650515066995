import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const TextField = React.forwardRef((props, ref) => {
  const { label, required, name, error, errorText, className, style, ...others } = props;

  const classInput = classnames(
  "mt-2 py-2 px-2 w-full text-center font-primary font-medium text-sm rounded",
    className,
  );

  const styleInput = {
    backgroundColor: '#602413',
    borderRadius: '28px',
    color: '#FFF3B7',
    fontSize: '24px',
    ...style,
  }
  
  return (
    <div>
      <div className="pt-4">
        <div className="text-center font-secondary text-uppercase text-white">
          {label}
        </div>
        {required && (
          <span className="pl-1 font-sans font-bold text-xs text-pink-header">
            *
          </span>
        )}
      </div>

      <input name={name} className={classInput} ref={ref} min={others?.min} style={styleInput} {...others} />

      {error && <div className="text-red-300 text-xs">{errorText}</div>}
    </div>
  );
});

export default TextField;

TextField.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
};
