// export const base_url = 'http://localhost:3333';
export const base_url = 'https://api-photomatics.demo-kota.com';
// export const base_url = 'https://api.photomatics.site';
export const api = `${base_url}`;
export const apiStatistics = `${base_url}/api/statistic`;
export const api_homepage = "http://localhost:8000/api/homepage";
export const reCaptcha = 
  "$2y$10$qmagQNReBigQlx.WB6jhEu1.OkL4WQ4qVQeQFpcwIyi.CtwwwAkAi";

// export const apiInaprocPengadaanLangsung = `https://inaproc.lkpp.go.id/isb/api/4da0c697-b1d9-4133-8865-aae675bc8530/json/678387789/D13VDrekapRUPukmPL/tipe/4:4:4/parameter/`;
// export const apiInaprocPUKecil = `https://inaproc.lkpp.go.id/isb/api/678b138f-0e56-4e54-aa0b-7239b4244279/json/678386607/D13VDrekapRUPukmTender/tipe/4:4:4/parameter/`;
// export const apiInaprocPUNonKecil = `https://inaproc.lkpp.go.id/isb/api/c9e21e2d-7b5a-43bf-8cd6-474d34612ec3/json/678382673/D13VDrekapRUPTenderNonUKM/tipe/4:4:4/parameter/`;

const endpoint = {
  // Payment
  transaction: {
    create: `${api}/transaction`,
    getStatus: `${api}/transaction/status/`,
    total: `${api}/transaction/total/byuser`,
    update: `${api}/transaction/`,
  },
  branch: {
    status: `${api}/branch/status`
  },
  auth: {
    login: `${api}/auth/login`,
    profile: `${api}/auth/profile`,
    logout: `${api}/auth/logout`,
    verifyPassword: `${api}/auth/verify-password`,
  },
  booth: {
    list: `${api}/booth/byuser`,
    update: `${api}/booth/`,
    detail: `${api}/booth/`,
  },
};

export default endpoint;
