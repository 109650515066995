import React from 'react';
import { styled } from '@material-ui/styles'

const H1 = styled('h1')({
  fontSize: 32
})

export const Title = (props) => {
  return <H1 {...props}>{props.children}</H1>
}

export const SecondaryText = (props) => {
  const style = {
    fontWeight: 'bold',
    fontStyle: 'italic',
    textTransform: 'uppercase',
    fontSize: '16px',
    color: 'white',
    ...props.style
  }
  return <span style={style} {...props}>{props.children}</span>
}