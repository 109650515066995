/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Button from "components/Button";
import {SecondaryText} from "components/Typography";
import Card from "components/Card";
import {Title} from "components/Typography";
import LayoutStandard from "components/Layout/Standard";
import {
  useTheme,
} from "@material-ui/styles";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import * as paymentService from 'services/payment';
import { useDispatch } from "react-redux";
import { createTransactionSuccess } from "redux/reducers/payment";
import useAuth from "hooks/useAuth";
import useBooth from "hooks/useBooth";

function PaymentFailed() {
  useAuth();
  const booth = useBooth();
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const { data } = useSelector((state) => state.payment);

  const handleClick = () => {
    paymentService.create({
      booth_id: booth.getSelected()?.id
    }, (response, isError) => {
      if(!isError) {
        dispatch(createTransactionSuccess({
          transactionId: response.data.transactionId,
          qrContent: response.data.qrContent,
          price: response.data.price,
          codeTransaction: response.data.code_transaction,
        }))
        window.dateValue = Date.now() + (1*60000);
        history.push('/payment')
      }
    })
  }

  useEffect(() => {
    window.localStorage.removeItem('TRX_CURRENT')
    window.localStorage.removeItem('TIMER_VALUE')

    setTimeout(() => {
      history.push('/')
    }, 60000)
  }, [])
  
  return (
    <LayoutStandard>
      <div className="flex flex-col items-center justify-center text-center">
        <SecondaryText className="my-4">Status Transaksi</SecondaryText>
        <Card className="flex flex-col justify-center" style={{width: '100%'}}>
          <img src="/assets/gagal.png" alt="qris" style={{ width: 'auto' }}/>
        </Card>
        <div className="text-center mt-5 mb-2">
          <p style={{color: theme.palette.secondary.main}}>
          Waktu tunggu pembayaran habis sehingga transaksi anda gagal.
<br/>
<br/>
Silahkan mendaftar kembali dari awal.
<br/>
<br/>
Jika ternyata dana anda terpakai, silahkan hubungi staff kami di photo booth.
          </p>
        </div>
        <div>
          <Button onClick={() => handleClick()}>
            Daftar Ulang
          </Button>
        </div>
      </div>
    </LayoutStandard>
  );
}

export default PaymentFailed;
