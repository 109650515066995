/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Button from "components/Button";
import { SecondaryText } from "components/Typography";
import Card from "components/Card";
import { Title } from "components/Typography";
import LayoutStandard from "components/Layout/Standard";
import { useTheme } from "@material-ui/styles";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import useAuth from "hooks/useAuth";
import useBooth from "hooks/useBooth";
import { formatRupiah } from "utils/helpers";
import { DateTime } from "luxon";
import { get } from "lodash";

console.log("🚀 ~ file: PaymentSuccess.js ~ line 15 ~ DateTime", DateTime)
function PaymentSuccess() {
  const { user } = useAuth();
  console.log(
    "🚀 ~ file: PaymentSuccess.js ~ line 20 ~ PaymentSuccess ~ user",
    user
  );
  const theme = useTheme();
  const { data } = useSelector((state) => state.payment);
  const history = useHistory();
  const [trxActive, setTrxActive] = useState(null);
  const booth = useBooth();
  console.log(
    "🚀 ~ file: PaymentSuccess.js ~ line 22 ~ PaymentSuccess ~ trxActive",
    trxActive
  );

  useEffect(() => {
    if (!window.localStorage.getItem("TRX_ACTIVE")) {
      window.localStorage.setItem(
        "TRX_ACTIVE",
        window.localStorage.getItem("TRX_CURRENT")
      );
    }

    window.localStorage.removeItem("TRX_CURRENT");
    window.localStorage.removeItem("TIMER_VALUE");

    setTrxActive(JSON.parse(atob(window.localStorage.getItem("TRX_ACTIVE"))));
  }, []);

  return (
    <LayoutStandard>
      <div className="flex flex-col items-center justify-center text-center">
        <SecondaryText className="my-4">Status Transaksi</SecondaryText>
        <Card
          className="flex flex-col justify-center"
          style={{ width: "100%" }}
        >
          <img
            src="/assets/berhasil.png"
            alt="qris"
            style={{ width: "auto" }}
          />
        </Card>
        <div className="text-center mt-8">
          <span className="font-secondary text-uppercase text-white">
            Total Biaya:
          </span>
          <Title
            className="text-center"
            style={{ color: theme.palette.secondary.main, fontSize: 24 }}
          >
            {formatRupiah(data.price)}
          </Title>
        </div>
        <div
          className="text-center mt-5 mb-2 w-full"
          style={{ marginBottom: 125 }}
        >
          <span className="font-secondary text-uppercase text-white">
            Detail Transaksi:
          </span>
          <div style={{ color: theme.palette.secondary.main }}>
            <div className="flex justify-between">
              <span className="whitespace-nowrap">ID Transaksi:</span>
              <div
                style={{
                  width: "100%",
                  borderBottom: `1px solid ${theme.palette.secondary.main}`,
                  marginLeft: 5,
                  marginRight: 5,
                  marginBottom: 7
                }}
              ></div>
              <span className="whitespace-nowrap">
                {get(trxActive, 'code_transaction')}
              </span>
            </div>
            <div className="flex">
              <span className="whitespace-nowrap">Waktu:</span>
              <div
                style={{
                  width: "100%",
                  borderBottom: `1px solid ${theme.palette.secondary.main}`,
                  marginLeft: 5,
                  marginRight: 5,
                  marginBottom: 7
                }}
              ></div>
              <span className="whitespace-nowrap">
                {DateTime.fromISO(get(trxActive, "updatedAt"))
                  .setLocale("id")
                  .toFormat("cccc, dd/MM/yyyy HH:mm")}
              </span>
            </div>
            <div className="flex justify-between">
              <span className="whitespace-nowrap">Urutan:</span>
              <div
                style={{
                  width: "100%",
                  borderBottom: `1px solid ${theme.palette.secondary.main}`,
                  marginLeft: 5,
                  marginRight: 5,
                  marginBottom: 7
                }}
              ></div>
              <span className="whitespace-nowrap">
                {Number(window.localStorage.getItem("TRX_TOTAL")) + 1}
              </span>
            </div>
            <div className="flex justify-between">
              <span className="whitespace-nowrap">Cabang:</span>
              <div
                style={{
                  width: "100%",
                  borderBottom: `1px solid ${theme.palette.secondary.main}`,
                  marginLeft: 5,
                  marginRight: 5,
                  marginBottom: 7
                }}
              ></div>
              <span className="whitespace-nowrap">{get(user, "branch.name")}</span>
            </div>
            <div className="flex justify-between">
              <span className="whitespace-nowrap">Booth:</span>
              <div
                style={{
                  width: "100%",
                  borderBottom: `1px solid ${theme.palette.secondary.main}`,
                  marginLeft: 5,
                  marginRight: 5,
                  marginBottom: 7
                }}
              ></div>
              <span className="whitespace-nowrap">{get(booth.getSelected(), "name")}</span>
            </div>
          </div>
        </div>
        <div className="mt-4 content-footer">
          <Button onClick={() => history.push("/takepicture")}>
            Mulai Ambil Gambar
          </Button>
        </div>
      </div>
    </LayoutStandard>
  );
}

export default PaymentSuccess;
