import {request, requestApiDesktop} from 'utils/request'
import endpoints from 'utils/endpoint'
import { FlashAutoTwoTone } from '@material-ui/icons';
import session from 'utils/session'


export default function useBooth() {
  const key = 'BOOTH_SELECTED'
  
  return {
    getSelected() {
      const rawData = window.localStorage.getItem(key);

      if(!rawData) {
        return null;
      }
      
      const data = JSON.parse(atob(rawData))

      return data;
    },

    setSelected(booth) {
      window.localStorage.setItem(key, btoa(JSON.stringify(booth)))
    },

    getList() {
      return request(endpoints.booth.list).then(response => response.data)
    },

    async isConnected(syncToAPI = true) {
      const booth = this.getSelected();
      const result = {
        message: '',
        detail: '',
        status: false,
        reachable: false,
      }

      if(!booth.ip || !booth.port) {
        result.status = false;
        result.message = 'Belum Terhubung';

        if(!booth.ip && !booth.port) {
          result.detail = 'IP & Port belum ditentukan';
        } else if(booth.ip && !booth.port) {
          result.detail = 'Port belum ditentukan'
        } else if(!booth.ip && booth.port) {
          result.detail = 'IP belum ditentukan'
        }
        
        if(syncToAPI) {
          this.updateBooth({
            status: 'inactive',
            progress_status: '-',
          })
        }
        
        return Promise.reject(result);
      }

      try {
        await requestApiDesktop({
          url: '/'
        }, booth.ip, Number(booth.port))
        
        result.message = 'Terhubung'
        result.detail = ''
        result.status = true
        result.reachable = true

        this.registerBoothToApp();

        if(syncToAPI) {
          this.updateBooth({
            status: 'active',
            progress_status: 'connected',
          })
        }
        
        return Promise.resolve(result)
      } catch (error) {        
        result.message = 'Tidak Terhubung'

        if(error.message === 'Network Error') {
          // result.detail = 'Sertifikat belum diinstall'
          result.reachable = true
        } else {
          result.detail = ''
          result.reachable = false
        }
        result.status = false

        if(result.reachable) {
          this.updateBooth({
            status: 'active',
            progress_status: 'not connected',
          })
        } else {
          if(syncToAPI) {
            this.updateBooth({
              status: 'inactive',
              progress_status: 'not connected',
            })
          }
        }
        
        return Promise.reject(result)
      }
    },

    downloadCertificate() {
      const booth = this.getSelected();
      const port = Number(booth.port)+1;
      const link = `http://${booth.ip}:${port}/download-cert`
      window.open(link, '_blank') || window.location.replace(link)
    },

    trigger(eventName) {
      const booth = this.getSelected();

      switch (eventName) {
        case 'block':
          return requestApiDesktop({
            url: '/window?visible=1'
          }, booth.ip, Number(booth.port))
        case 'unblock':
          return requestApiDesktop({
            url: '/window?visible=0'
          }, booth.ip, Number(booth.port))
        default:
          break;
      }
    },

    registerBoothToApp() {
      const booth = this.getSelected();
      return requestApiDesktop({
        url: '/register-booth',
        data: {...booth, sid: window.btoa(session.getToken())},
        method: 'POST',
      }, booth.ip, Number(booth.port))
    },

    updateBooth(payload) {
      const booth = this.getSelected();

      return request(endpoints.booth.detail + booth.id, {
        method: 'PATCH',
        data: payload,
      })
    }
  }
}