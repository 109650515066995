import React from "react";
import LKPPLogo from "assets/logo-LKPP-footer.png";
import { Phone, Mail, Print } from "@material-ui/icons";
import { useSelector } from "react-redux";
import get from "lodash/get";
import { makeStyles, createStyles } from "@material-ui/styles";
import classnames from "classnames";

const useStyles = makeStyles((theme) =>
  createStyles({
    footerBackground: {
      backgroundImage: `url('/static/images/footer.jpg')`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% 100%",

      [theme.breakpoints.down("lg")]: {
        backgroundSize: "cover",
      },
    },
  })
);

function Footer(props) {
  const { aboutus } = useSelector((state) => state.region);

  const { visitor } = useSelector((state) => state.region);

  const classes = useStyles();

  return (
    <footer
      className={classnames(
        classes.footerBackground,
        "bg-white pt-8 border-t border-solid border-pink-header"
      )}
    >
      <div className="flex lg:flex-row flex-col lg:px-0 px-6 lg:space-y-0 space-y-4 justify-around">
        <div className="">
          <img src={LKPPLogo} alt="" className="h-24 w-auto" />
        </div>
        <div className="flex">
          <div className="flex flex-col">
            <div className="text-black-2 font-montserrat font-bold text-sm">
              Statistik pengunjung
            </div>
            {visitor?.map((_item, index) => (
              <div
                className="font-montserrat font-medium text-sm pt-4"
                key={index}
              >
                {_item.label}
              </div>
            ))}
          </div>
          <div className="flex flex-col pt-4">
            {visitor?.map((_item, index) => (
              <div
                className="font-montserrat font-medium text-sm pt-4"
                key={index}
              >
                :
              </div>
            ))}
          </div>

          <div className="flex flex-col pt-4 text-right pl-3">
            {visitor?.map((_item, index) => (
              <div className="flex justify-end" key={index}>
                <div className="font-extrabold font-montserrat text-sm pt-4 pr-1">
                  {_item.value}
                </div>
                <div className="font-montserrat font-medium text-sm pt-4">
                  Pengunjung
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="flex flex-col pb-16">
          <div className="font-montserrat font-bold text-xs text-black-2">
            {get(aboutus, "judul")}
          </div>
          <div className="py-3 font-montserrat font-medium text-sm text-black-2">
            {get(aboutus, "judul_alamat")}
            <div
              dangerouslySetInnerHTML={{
                __html: get(aboutus, "alamat_lengkap"),
              }}
            ></div>
          </div>
          <div className="flex flex-row items-center">
            <Phone color="primary" />
            <div className="px-2 font-montserrat font-medium text-sm text-pink-header">
              Phone:
            </div>
            <div className="font-montserrat font-medium text-sm text-black-2">
              {get(aboutus, "phone")}
            </div>
          </div>

          <div className="flex flex-row items-center pt-2">
            <Print color="primary" />
            <div className="px-2 font-montserrat font-medium text-sm text-pink-header">
              Fax:
            </div>
            <div className="font-montserrat font-medium text-sm text-black-2">
              {get(aboutus, "fax")}
            </div>
          </div>

          <div className="flex flex-row items-center pt-2">
            <Mail color="primary" />
            <div className="px-2 font-montserrat font-medium text-sm text-pink-header">
              Email:
            </div>
            <div className="font-montserrat font-medium text-sm text-black-2">
              {get(aboutus, "email")}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-black-2 font-montserrat font-medium text-sm text-border-on-pink flex justify-center py-5 lg:px-0 px-6">
        Copyright @ 2021 Lembaga Kebijakan Pengadaan Barang/Jasa Pemerintah
      </div>
    </footer>
  );
}

export default Footer;
