import axios from 'axios';
import endpoint from 'utils/endpoint';
import token from 'utils/session';
import Cookie from 'js-cookie';
import { request } from 'utils/request';

export async function create(data,callback) {
  const url = endpoint.transaction.create;
  const config = {
      method: 'POST',
      data,
  };

  try {
      const response = await request(url,config);
      window.localStorage.setItem('TRX_CURRENT', btoa(JSON.stringify(response.data.data)))
      await callback(response.data, false);
  } catch (error) {
      console.log(error);
      await callback(error, true);
  }
}

export async function getStatus({transactionId, forceFailed = false},callback) {
  const url = `${endpoint.transaction.getStatus}${transactionId}`;
  const config = {
      method: 'GET',
      params: {
          forceFailed: Number(forceFailed)
      }
  };

  try {
      const response = await request(url,config);
      await callback(response.data, false);
  } catch (error) {
      console.log(error);
      await callback(error, true);
  }
}
export async function update(data,callback) {
    const url = endpoint.transaction.update + data.id;
    const config = {
        method: 'PATCH',
        data,
    };
  
    try {
        const response = await request(url,config);
        await callback(response.data, false);
    } catch (error) {
        console.log(error);
        await callback(error, true);
    }
  }