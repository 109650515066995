import { useState, useRef } from 'react'
import { Box } from "@material-ui/core";
import { makeStyles, createStyles, useTheme } from "@material-ui/styles";
import classNames from "classnames";
import { Title } from "components/Typography";
import Select from "components/TextField/Select"
import Button from "components/Button"
import './Login.css'
import { useDispatch, useSelector } from 'react-redux'
import { Alert } from "@material-ui/lab";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {get} from 'lodash'
import useBooth from "hooks/useBooth";
import useAuth from "hooks/useAuth";
import session from "utils/session";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      // marginTop: 110,
      backgroundColor: theme.palette.primary.main,
      height: '100vh',
      textAlign: 'center',
      paddingTop: '5vh',
      paddingLeft: '2vw',
      paddingRight: '2vw',
      alignItems: 'center',
      // justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',

    },
    scrollMargin: {
      scrollMarginTop: "120px",
    },
    image: {
      width: "100%",
      height: 516,
      objectFit: "cover",

      [theme.breakpoints.down("lg")]: {
        height: 230,
      },
    },
  })
);

function ChooseBooth() {
  useAuth();
  const classes = useStyles();
  const theme = useTheme();
  const {message, profile} = useSelector((state) => state.auth);
  const history = useHistory();
  const booth = useBooth();
  const [listBooth, setListBooth] = useState([]);
  const selectRef = useRef()

  useEffect(() => {
    booth.getList().then(data => {
      setListBooth(data)
    })
  }, [])
  
  const handleChooseBooth = () => {
    const value = selectRef.current.value;
    const selected = listBooth.filter(data => data.id == value)[0]
    booth.setSelected(selected)

    history.push('/')
  }

  const handleClickLogout = () => {
    session.clear();
    window.location.replace('/login')
  }
  
  return (
    <div className="login-container">
      <Box className={classNames(classes.root, "font-primary")}>
        <div>
          <img className="img-logo" src="/assets/logo.png" alt="Logo Photomatics" />
        </div>
        <hr />
        <Title style={{ color: theme.palette.secondary.main }}>Payment Portal</Title>
        <div className="mt-10">
          <div className="text-center font-secondary text-uppercase text-white">
            Cabang
          </div>
          <Title
            className="text-center"
            style={{ color: theme.palette.secondary.main, fontSize: 24 }}
          >
            {get(profile, 'branch.name')}
          </Title>
            {message && (
              <Alert severity="error">{message}</Alert>
            )}
            <div className="mb-5">
              <Select 
                ref={selectRef}
                label="Choose Booth" 
                name="choose_booth"
                options={listBooth.map((data) => ({
                  label: data.name,
                  value: data.id,
                  ...data,
                }))}
                style={{width: '100%'}}
              />
            </div>
            <div className="mt-2">
              <Button variant="outlined" type="button" onClick={handleClickLogout} style={{ marginRight: 10 }}>Logout</Button>
              <Button type="submit" onClick={handleChooseBooth}>Pilih Booth</Button>
            </div>
        </div>
      </Box>
    </div>
  )
}

export default ChooseBooth