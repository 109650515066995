import { request } from "../utils/request";
import endpoints from "../utils/endpoint";
import { setLoading, setLogin, setMessage, setProfile } from "../redux/reducers/auth";
import { get } from "lodash";

export const doLogin = (params) => (dispatch) => {
  dispatch(
    setLoading({
      stateName: "login",
      value: true,
    })
  );

  const data = {
    username: params.username,
    password: params.password,
    type: 'user',
  };

  const config = {
    method: 'POST',
    data
  }
  return request(endpoints.auth.login, config)
    .then((res) => {
      dispatch(
        setLogin({
          token: res.data.accessToken,
          user: res.data.user,
        })
      );
    })
    .catch((e) => {
      dispatch(
        setLoading({
          stateName: "login",
          value: false,
        })
      );

      const message = get(e, "response.data.message", "Something Wrong");

      dispatch(setMessage(message));

      return e;
    });
};

export const getProfile = () => (dispatch) => {
  return request(endpoints.auth.profile).then((response) => {
    dispatch(setProfile(response.data.data))
  })
}