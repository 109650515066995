import { createSlice } from "@reduxjs/toolkit";
import endpoint from "utils/endpoint";
import axios from "axios";

const newsSlices = createSlice({
  name: "payment",
  initialState: {
    isLoad: false,
    error: null,
    isError: false,
    data: {
      transactionId: null,
      codeTransaction: null,
      qrContent: '',
      price: 0,
      expiredInMinutes: 0,
    },
  },
  reducers: {
    startLoading: (state) => {
      state.isLoad = true;
    },
    hasError: (state, { payload }) => {
      state.isLoad = false;
      state.error = payload;
    },
    createTransactionSuccess: (state, { payload }) => {
    console.log("🚀 ~ file: payment.js ~ line 27 ~ payload", payload)
      state.isLoad = false;
      state.data.transactionId = payload.transactionId;
      state.data.qrContent = payload.qrContent;
      state.data.price = payload.price;
      state.data.expiredInMinutes = payload.expiredInMinutes;
      state.data.codeTransaction = payload.code_transaction || payload.codeTransaction;
      console.log("🚀 ~ file: payment.js ~ line 35 ~ payload.code_transaction", payload.code_transaction)
    },
  },
});

export const {
  startLoading,
  hasError,
  createTransactionSuccess
} = newsSlices.actions;

export default newsSlices.reducer;